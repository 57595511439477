import { Spacer } from "~/widgets/spacer";
import { VoucherDetails } from "~/server/types/order";
import { ProfileCardsList } from "../../brand-collection-profile/profile_cards_list";
import { BackButtonReceiver } from "../pluto_receiver_components";
import { goBack } from "~/shared_states/modal";
import PlutoBranding from "../pluto_branding";

export type PlutoMyGiftCardsPageProps = {
  giftingKey: string;
  coinsCount: number;
  phoneNumber: string;
  cardList: VoucherDetails[];
  logoUrl?: string;
};

export function PlutoMyGiftCardsPageComponent(
  props: PlutoMyGiftCardsPageProps
) {
  return (
    <div class="no-scrollbar  flex h-full w-full flex-col items-center overflow-y-scroll bg-baseDark ">
      <div class=" relative  z-10 flex min-h-screen w-full flex-col items-center text-baseTertiaryLight">
        <div class="flex w-full flex-row items-center justify-between p-5 text-white">
          <BackButtonReceiver />
        </div>
        <div class="no-scrollbar  flex  h-full w-full max-w-[450px] flex-col items-start justify-start overflow-x-hidden px-4 sm:max-w-[343px] sm:px-0 ">
          <Spacer height={20} />
          <span class="text-h3">My gift cards</span>
          <Spacer height={24} />
          <ProfileCardsList cardList={props.cardList} />
          <div class=" w-full pb-[18px]">
            <PlutoBranding />
          </div>
        </div>
      </div>
    </div>
  );
}
